import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/504def04a2d1b7ac80c00a120e439038f280eef328895997df00132ab32427d5/navBarModifier';
import SharedProviders_1_0 from './extensions/fb7d66cd9cd2112e9197bf5dbd129e7b47b56383d43464dcdab0f67a43830f63/providers';
import SharedProviders_2_0 from './extensions/software-houses/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
