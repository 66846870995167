import { addNavMenuSection } from '@vendure/admin-ui/core';

export default [
  addNavMenuSection(
    {
      id: 'administration-section',
      label: 'Administration',
      items: [
        {
          id: 'software-houses-tab',
          label: 'Software Houses',
          routerLink: ['/extensions/software-houses'],
          icon: 'cloud'
        }
      ]
    },
    'settings'
  )
];
